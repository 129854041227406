<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i>
            </router-link>
          </li>
          <li>{{ this.$t('menu.company_dashboard.advertisement') }}</li>
          <li>{{ this.$t('general.banner_list') }}</li>
        </ul>
      </nav>
    </div>
    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{ this.$t('general.banner_list') }} </span>
        </h2>
      </div>
      <div class="section-header-right">
        <router-link :to="{ name: 'company.advertisement.add' }" class="btn btn-default">
          <i class="uil-plus"></i> Reklam Ekle
        </router-link>
      </div>
    </div>

    <template v-if="errorMessages">
      <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: red">
          <i class="uil-exclamation-triangle"></i> {{ error }}
        </p>
      </div>
    </template>
    <div v-if="successMessage" class="uk-alert-success" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: green">
        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
      </p>
    </div>
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <!-- <div>
              <a href="#" class="btn btn-icon btn-hover btn-circle" aria-expanded="false">
                <i class="uil-search"></i>
              </a>
              <div class="uk-drop" uk-drop="mode: hover; pos: right-center; offset: 0" style="left: 91px; top: 159.297px;">
                <form class="uk-search uk-search-navbar uk-width-1-1">
                  <input class="uk-search-input shadow-0 uk-form-small" type="search" :placeholder="$t('general.search')" autofocus="" v-model="filter.search">
                </form>
              </div>
            </div> -->
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                :placeholder="$t('general.search')" />
            </div>
          </div>
          <div class="d-flex">
            <!-- Shorting -->
            <a href="#" class="btn btn-icon btn-hover  btn-circle" :uk-tooltip="$t('filters.sort_list')" title=""
              aria-expanded="false">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
              <ul class="uk-nav uk-dropdown-nav">
                <li @click="sortOrder('name', 'asc')">
                  <a href="javascript:void(0)">{{ $t("sorting.a_z") }}</a>
                </li>
                <li @click="sortOrder('name', 'desc')">
                  <a href="javascript:void(0)">{{ $t("sorting.z_a") }}</a>
                </li>
                <li @click="sortOrder('created_at', 'asc')">
                  <a href="javascript:void(0)">
                    {{ $t("sorting.created_at_asc") }}</a>
                </li>
                <li @click="sortOrder('created_at', 'desc')">
                  <a href="javascript:void(0)">
                    {{ $t("sorting.created_at_desc") }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="table-responsive">
        <table class="table align-items-center">
          <thead>
            <tr>
              <th scope="col">{{ this.$t('general.title') }}</th>
              <th scope="col">{{ this.$t('general.connection') }}</th>
              <th scope="col">{{ this.$t('general.added_date') }}</th>
              <th scope="col">{{ this.$t('general.status') }}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody class="list">
            <tr v-for="(item, index) in formattedItems" :key="'advertisement_list_item_' + index">
              <th scope="row">
                <div class="media align-items-center">
                  <div>
                    <div class="avatar-parent-child" style="width: max-content">
                      <img alt="Image placeholder" :src="item.image_link" class="avatar" />
                    </div>
                  </div>
                  <div class="media-body ml-4">
                    <a href="#" class="name h6 mb-0 text-sm">{{ item.name }}</a>
                    <small class="d-block font-weight-bold">#{{ item.id }}</small>
                  </div>
                </div>
              </th>
              <td>{{ item.url }}</td>
              <td><i class="uil-calendar-alt"></i> {{ item.created_at }}</td>
              <td><i class="uil-check"></i> {{ item.status }}</td>
              <td class="text-right">
                <div class="actions ml-3">
                  <router-link :to="{
                    name: 'company.advertisement.edit',
                    params: { id: item.id },
                  }" class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('faq_categories.edit')">
                    <i class="uil-edit "></i>
                  </router-link>
                  <a href="javascript:void(0)" class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Sil"
                    title="" @click="removeItem(item.id)" aria-expanded="false">
                    <i class="uil-minus-circle text-danger"></i>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import moment from "moment";
import module, {
  BASE_URL,
  MODULE_NAME,
  DELETE_ITEM,
  ITEMS,
  GET_ITEMS,
  ERROR,
} from "@/core/services/store/advertisement.module";
import Pagination from "@/assets/components/Pagination";

export default {
  name: "AdvertisementList",
  components: { Pagination },
  data() {
    return {
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    formattedItems() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;

      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          name: currentItem.name,
          url: currentItem.url,
          text: currentItem.text ? currentItem.text : "-",
          image_link: currentItem.image_link,
          status: currentItem.status_id
            ? this.$t("general.active")
            : this.$t("general.passive"),
          created_at: currentItem.created_at
            ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });
      return results;
    },
  },
  methods: {
    getResult() {
      let self = this;
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          like: self.filter.search,
          page: self.page,
          per_page: self.per_page,
          sort: self.filter.sort,
          order: self.filter.order,
        },
      });
    },
    removeItem(id) {
      this.$store
        .dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
          url: BASE_URL + "/" + id,
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_deleted");
            this.getResult();
          } else this.errorMessages.push(this.error);
        });
    },
    sortOrder(sort, order) {
      let self = this;
      self.page = 1;
      self.filter.sort = sort;
      self.filter.order = order;

      this.getResult();
    },
    setPage(page) {
      let self = this;
      self.page = page;
      this.getResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getResult();
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
